import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, Fragment, useEffect, useState, useContext } from "react";
import DataContext from "./Context/DataContext/Context";
import React from "react";
import "./SASS/main.css";
import { Routing } from "./Routes";
import PageLoading from "./Components/PageLoading";
import Auth from "./Config/Auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import secureLocalStorage from "react-secure-storage";

function App() {
  const ContextData = useContext(DataContext);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <RenderRoutes token={ContextData?.token} data={ContextData} />
      </BrowserRouter>
    </>
  );
}

export default App;

const RenderRoutes = ({ token, data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [user, setLoginUser] = useState(false);

  const updateUser = (user) => {
    console.log("usersasdsa", user);
    secureLocalStorage.setItem("redesk-user", JSON.stringify(user));
    setLoginUser(user);
    // setIsTokenExpire();
  };

  const company_domain = data.organizationKey;
  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        {Routing &&
          Routing.map((values, i) => {
            const Component = values?.element;
            const Layout = values.layout || Fragment;
            const Guard = values?.guard ? Auth : Fragment;
            const Path = values?.sub_domain ? company_domain : "";
            return (
              <React.Fragment key={i}>
                <Route
                  path={
                    values?.sub_domain
                      ? `/${Path}${values?.path}`
                      : values?.path
                  }
                  element={
                    <Guard updateUser={updateUser}>
                      <Layout>
                        <Component
                          token={token}
                          updateUser={updateUser}
                          user={user}
                          {...Routing}
                        />
                      </Layout>
                    </Guard>
                  }
                />
              </React.Fragment>
            );
          })}
      </Routes>
    </Suspense>
  );
};
