import React, { useEffect, useState } from "react";
import DataContext from "./Context";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import ApiConfig from "../../Config/ApiConfig";
import { Navigate } from "react-router";
const ContextState = (props) => {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const UserObject = {};
  const OrganizationObject = {};
  useEffect(() => {
    if (secureLocalStorage.getItem("redesk-user")) {
      setUser(secureLocalStorage.getItem("redesk-user"));
    }
  }, [user]);
  useEffect(() => {
    if (secureLocalStorage.getItem("redesk-token")) {
      setToken(secureLocalStorage.getItem("redesk-token"));
    }
  }, [user]);
  const [organizationKey, setOrganizationKey] = useState(
    secureLocalStorage.getItem("redesk-company")
  );

  console.log("organizationKey", organizationKey);
  console.log("organizationKey12", OrganizationObject);

  const [organizationList, setOrganizationList] = useState();
  const [taskAdd, setTaskAdd] = useState(0);

  const [userList, setUserList] = useState();

  const [companyList, setCompanyList] = useState();
  const handleGetorganization = async () => {
    try {
      const res = await axios.get(ApiConfig?.getOrganisationList);
      if (res.status === 200 || res.data.status === "201") {
        setCompanyList(res.data.data);
        setOrganizationList(res.data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    handleGetorganization();
  }, []);

  const handleUserList = async () => {
    try {
      const res = await axios.get(ApiConfig.user, {
        headers: { Authorization: token },
      });
      if (res.status === 200 || res.data.status === "200") {
        setUserList(res?.data?.docs);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  };

  if (userList) {
    for (let i = 0; i < userList.length; i++) {
      UserObject[userList[i]?._id] = userList[i]?.name;
    }
  }
  if (organizationList) {
    for (let i = 0; i < organizationList.length; i++) {
      OrganizationObject[organizationList[i]?.sub_domain] =
        organizationList[i]?.organisation_name;
    }
  }

  useEffect(() => {
    if (token) {
      handleUserList();
    }
  }, [token]);

  const SessionLogout = async (error) => {
    const response_code = await error?.response?.data?.status;
    const response_message = await error?.response?.data?.message;
    if (
      response_code === 401 ||
      response_message === "Unauthorized!TokenExpiredError: jwt expired"
    ) {
      secureLocalStorage.removeItem("redesk-user");
      secureLocalStorage.removeItem("redesk-token");
      secureLocalStorage.removeItem("redesk-organization");
      setToken();
      setUser();
    }
  };

  const handleLogout = async () => {
    secureLocalStorage.removeItem("redesk-user");
    secureLocalStorage.removeItem("redesk-token");
    secureLocalStorage.removeItem("redesk-organization");
    setToken();
    setUser();
  };

  return (
    <DataContext.Provider
      value={{
        token,
        setToken,
        OrganizationObject,
        setCompanyList,
        companyList,
        organizationList,
        setOrganizationList,
        user,
        setUser,
        organizationKey,
        taskAdd,
        setTaskAdd,
        setOrganizationKey,
        UserObject,
        userList,
        setUserList,
        SessionLogout,
        handleLogout,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default ContextState;
