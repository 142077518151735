// export const baseurl = "http://192.168.101.7:8001";
// export const baseurl = "http://localhost:8001";
export const baseurl = "https://api.redesk.in";

const ApiConfig = {
  signupwithorgs: `${baseurl}/api/signupwithorgs`,
  signinwithorgs: `${baseurl}/api/signinwithorgs`,
  projectList: `${baseurl}/api/projectList`,
  getOrganisationList: `${baseurl}/api/getOrganisationList`,
  createorganisation: `${baseurl}/api/createOrganisation`,
  addproject: `${baseurl}/api/project`,
  verify_organisation: `${baseurl}/api/verifyorganisation`,
  projectdetails: `${baseurl}/api/projectdetails`,
  projectEdit: `${baseurl}/api/projectEdit`,
  projectTaskCount: `${baseurl}/api/projectTaskCount`,
  changeUserRoles: `${baseurl}/api/changeUserRoles`, //role change
  y: `${baseurl}/api/taskListByProject`,
  changeTaskStatus: `${baseurl}/api/changeTaskStatus`,
  getdashboarddetails: `${baseurl}/api/getdashboarddetails`,
  userList: `${baseurl}/api/userList`,
  taskList: `${baseurl}/api/taskList`,
  Task: `${baseurl}/api/Task`,
  forgetPassword: `${baseurl}/api/forgetPassword`,
  changePassword: `${baseurl}/api/changePassword`,
  taskdetailsbyID: `${baseurl}/api/task/`,
  sendInviteFromCSV: `${baseurl}/api/sendInviteFromCSV`,
  taskcount: `${baseurl}/api/taskcount`,
  projectEfficiency: `${baseurl}/api/projectEfficiency/`,
  projectMembers: `${baseurl}/api/projectMembers`,
  userApproveOrReject: `${baseurl}/api/userApproveOrReject`,
  sendInvite: `${baseurl}/api/sendInvite`,
  teamleader: `${baseurl}/api/teamleader`,
  observerlist: `${baseurl}/api/observerlist`,
  employeelist: `${baseurl}/api/employeelist`,
  clientList: `${baseurl}/api/clientList`,
  user: `${baseurl}/api/allUserFromOrgs`,
  taskArray: `${baseurl}/api/taskArray`,
  createclient: `${baseurl}/api/createclient`,
  editClient: `${baseurl}/api/editClient/`,
  addTaskComment: `${baseurl}/api/addTaskComment`,
  createobserver: `${baseurl}/api/createobserver`,
  editObserver: `${baseurl}/api/editObserver/`,
  createsubadmin: `${baseurl}/api/createsubadmin`,
  subadminList: `${baseurl}/api/subadminList`,
  editsubadmin: `${baseurl}/api/editsubadmin/`,
  addProjectAttachment: `${baseurl}/api/addProjectAttachment/`,
  loggedInUser: `${baseurl}/api/loggedInUser`,
  createCategory: `${baseurl}/api/createCategory`,
  categorylist: `${baseurl}/api/categorylist`,
  fileList: `${baseurl}/api/fileList`,
  folderList: `${baseurl}/api/folderList `,
  upload: `${baseurl}/api/upload`,
  taskDelete: `${baseurl}/api/task/`,
  projectAttachmentDelete: `${baseurl}/api/deleteprojectAttachment/`,
  changeProjectStatus: `${baseurl}/api/changeProjectStatus/`,
};

export default ApiConfig;
