import { useState, useEffect, useContext } from "react";
import DataContext from "../Context/DataContext/Context";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";

const Auth = (props) => {
  const ContextData = useContext(DataContext);
  // const [authCheck, setAuthCheck] = useState(
  //   ContextData?.token && ContextData?.user ? true : false
  // );
  const [authCheck, setAuthCheck] = useState(async () => {
    const token = await ContextData?.token;
    if (token) {
      let decoded = jwt_decode(token);
      if (decoded?.exp < Date.now() / 1000) {
        return true;
      }
    } else {
      return false;
    }
  });
  const [authLoader, setAuthLoader] = useState(false);

  const handleCheckToken = async () => {
    setAuthLoader(true);
    const token = await ContextData?.token;
    if (token) {
      console.log("Please cecksadasd", "Please cecksadasd");
      let decoded = jwt_decode(token);
      if (decoded?.exp < Date.now() / 1000) {
        console.log("Expires Not", "Not Expired");
        setAuthCheck(true);
      } else {
        setAuthCheck(false);
      }
    } else {
      setAuthCheck(false);
    }
  };
  // useEffect(() => {
  //   console.log("check", "check");
  //   handleCheckToken();
  // }, [props?.children]);

  // useEffect(() => {
  //   if (ContextData?.token && ContextData?.user) {
  //     setAuthCheck(true);
  //   }
  // }, [authCheck]);

  // let decoded = jwt_decode(ContextData?.token);
  // console.log("decoded0", decoded);
  // console.log("decoded1", Date.now() / 1000);
  // console.log(
  //   "decoded2",
  //   Date.now() / 1000 > decoded?.exp ? " Expired" : "Expires Not"
  // );
  // useEffect(() => {
  //   if (ContextData?.token) {
  //     let decoded = jwt_decode(ContextData?.token);
  //     if (decoded?.exp < Date.now() / 1000) {
  //       console.log("Expires Not", "Not Expired");
  //     } else {
  //       console.log("Expires", "Expires");
  //     }
  //   }
  // }, [children]);

  const IncorrectAuth = () => {
    return <Navigate to="/" />;
  };
  return authCheck ? props?.children : <IncorrectAuth />;
};

export default Auth;
