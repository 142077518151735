import React from "react";

const Footer = () => {
  return (
    <footer class="bg-light text-center text-lg-start">
      <div class="text-center footer-copywrite p-2">
        Powered By :{" "}
        <a
          class=" main-color-text"
          href="https://apptimates.com/"
          style={{
            textDecoration: "none",
          }}
        >
          Apptimate Software Pvt Ltd.
        </a>
      </div>
    </footer>
  );
};

export default Footer;
