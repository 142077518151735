import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OrganizationalLayout = ({ children }) => {
  const navigate = useNavigate();
  const [authTab, setAuthTab] = useState();

  useEffect(() => {
    setAuthTab(window.location.pathname.slice(1));
  });

  return (
    <>
      <div className="auth-layout-back container-fluid">
        <div className="row h-100">
          <div className="col-md-5 col-lg-6  auth-img-side">
            <div className="d-flex">
              <img
                src="./images/auth-img.png"
                alt="login"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-7 col-lg-6 ">
            <div className="form-container-box container-fluid">
              <div className="row bg-light form-box-row">
                <div className="col-md-12">
                  <div className="auth-logo-container">
                    <img
                      src="./images/redesk.png"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {window.location.pathname === "/login" ||
                window.location.pathname === "/signup" ? (
                  <>
                    <div className="col-md-6 p-0">
                      <div
                        className={
                          authTab === "login"
                            ? "login-tab auth-active-tab"
                            : "login-tab"
                        }
                        align="center"
                        onClick={() => {
                          setAuthTab("login");
                          navigate("/login");
                        }}
                      >
                        Login
                      </div>
                    </div>
                    <div className="col-md-6 p-0">
                      <div
                        className={
                          authTab === "signup"
                            ? "login-tab auth-active-tab"
                            : "login-tab"
                        }
                        align="center"
                        onClick={() => {
                          setAuthTab("signup");
                          navigate("/signup");
                        }}
                      >
                        Signup
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="col-md-12 p-0">
                  <div className="form-box ">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationalLayout;
// {children}
