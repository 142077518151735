import React, { useState, useContext, useEffect } from "react";
import DataContext from "../../Context/DataContext/Context";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  Tooltip,
  Badge,
  Avatar,
  Modal,
  Box,
} from "@mui/material";
import {
  Notifications,
  PowerSettingsNew,
  QuestionAnswer,
} from "@mui/icons-material";
import secureLocalStorage from "react-secure-storage";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { user_role } from "../../Config/ObjectConfig";
import ChatModule from "../../View/Dashboard/Chat";
import UserProfilePic from "../../Components/UserProfilePic";
import io from "socket.io-client";
import { baseurl } from "../../Config/ApiConfig";

// const socket = io.connect(baseurl);

// const settings = ["Profile", "Account", "Dashboard"];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  // p: 4,
};
const Header = ({ handleDrawerToggle }) => {
  const [message, setMessage] = useState();
  const [messageReceived, setMessageReceived] = useState("");

  const ContextData = useContext(DataContext);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (ContextData?.user) {
  //     socket.on("message", (data) => {
  //       setMessageReceived(data.message);
  //       console.log("data123", data);
  //     });
  //     socket.emit("joinproject", { id: ContextData?.user?._id });
  //     console.log("ContextData", ContextData?.user?._id);
  //   }
  // }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handlelogout = () => {
    ContextData?.handleLogout();
    navigate("/");
  };
  const settings = [
    {
      id: 1,
      label: "Profile",
      href: "/profile",
    },
    // {
    //   id: 1,
    //   label: "Change Password",
    //   href: `/${ContextData?.organizationKey}/newpassword`,
    // },
  ];
  return (
    <>
      <Toolbar className="dash-toolbar justify-content-between">
        <div>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="">
          <ChatModule />
          &nbsp;&nbsp; &nbsp;&nbsp;
          <Tooltip title="Notification">
            <IconButton size="large" onClick={() => {}}>
              <Badge badgeContent={17} color="error">
                <Notifications />
              </Badge>
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <Tooltip title="Profile">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ px: 2, borderRadius: "10px" }}
            >
              <UserProfilePic data={ContextData?.user} />
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              <Box
                className="avatar-container"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <div className="avatar-name">{ContextData?.user?.name}</div>
                <div className="avatar-role">
                  {user_role[ContextData?.user?.role]}
                </div>
              </Box>
            </IconButton>
          </Tooltip>
          &nbsp;
          <Tooltip title="Logout">
            <IconButton
              onClick={handleOpen}
              className="power-logout"
              // sx={{ px: 2, borderRadius: "10px" }}
            >
              <PowerSettingsNew />
            </IconButton>
          </Tooltip>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div class="card">
                <div class="card-header">Logout</div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    Are you sure you want to logout ?
                  </li>
                </ul>
                <div class="card-footer" align="right">
                  <button className="btn btn-secondary" onClick={handleClose}>
                    No
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn main-color-bg main-bg-text-color"
                    onClick={handlelogout}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Menu
            sx={{ mt: "45px", width: "200px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  navigate(`${setting?.href}`);
                }}
              >
                <h6 align="center">{setting?.label}</h6>
              </MenuItem>
            ))}
            {/* {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <h6 textAlign="center">{setting?.label}</h6>
              </MenuItem>
            ))} */}

            {/* <MenuItem onClick={handlelogout}>
              <h6 textAlign="center">Logout</h6>
            </MenuItem> */}
          </Menu>
        </div>
      </Toolbar>
    </>
  );
};

export default Header;
