import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import DataContext from "../../Context/DataContext/Context";
import { useState, useEffect, useContext } from "react";
import {
  Dashboard,
  ContentPasteGo,
  Task,
  Group,
  Settings,
  CalendarMonth,
  Inventory2,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import InnerLogo from "../../Components/InnerLogo";

const menuItems = [
  {
    item: "Dashboard",
    href: "/dashboard",
    icon: Dashboard,
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
  },
  {
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
    item: "Project",
    href: "/project",
    icon: ContentPasteGo,
  },
  {
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
    item: "Task",
    href: "/task",
    icon: Task,
  },
  {
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
    item: "File",
    href: "/file_manager",
    icon: Inventory2,
  },
  // {
  //   permission: ["admin", "team_leader", "user", "client","observer"],
  //   item: "Meeting",
  //   href: "/meeting",
  //   icon: CalendarMonth,
  // },
  {
    permission: ["admin", "subadmin", "team_leader", "observer"],
    item: "Users",
    href: "/userlist",
    icon: Group,
  },
  {
    permission: ["admin", "subadmin", "team_leader", "observer"],
    item: "Settings",
    href: "/settings",
    icon: Settings,
  },
];

const NavItem = ({ icon: Icon, label, href, permission }) => {
  const navigate = useNavigate();
  const ContextData = useContext(DataContext);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname === `/${ContextData?.organizationKey}${href}`
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [navigate]);

  if (permission?.includes(`${ContextData?.user?.role}`)) {
    return (
      <>
        <ListItemButton
          className={
            active ? "sidebar-active sidebar-listitem" : "sidebar-listitem"
          }
          onClick={() => {
            navigate(`/${ContextData?.organizationKey}${href}`);
          }}
        >
          <Icon style={{ fontSize: "30px" }} /> &nbsp;&nbsp;
          <ListItemText className="font-family-sidebar">{label}</ListItemText>
        </ListItemButton>
      </>
    );
  }
};
export const drawer = (
  <>
    <div className="px-3 my-3">
      <InnerLogo />
    </div>
    <Divider />
    <List>
      {menuItems.map((text, index) => {
        console.log("text", text);
        return (
          <>
            <ListItem key={index} disablePadding>
              <NavItem
                icon={text?.icon}
                label={text?.item}
                href={text?.href}
                permission={text?.permission}
              />
            </ListItem>
          </>
        );
      })}
    </List>
    <Divider />
  </>
);
